<script setup>
import { useStore } from 'vuex';

import SummaryOffcanvasDesktop from '@components/SummaryOffcanvasDesktop.vue';

const store = useStore();

const comebackUrl = window.API_URL;
</script>

<template>
    <SummaryOffcanvasDesktop id="summary-offcanvas" />

    <div class="d-none d-md-flex align-items-center justify-content-between mb-4">
        <div>
            <strong class="fs-5">{{
                $filters.getPriceFormatted(store.getters.getCurrentPrice)
            }}</strong>

            <div class="d-flex justify-content-between align-items-center gap-2">
                <p v-if="store.getters.getDiscountedPrice" class="mb-0 text-danger fs-8 fw-bold">
                    -{{ $filters.getPriceFormatted(store.getters.getDiscountedPrice) }}
                </p>
                <p v-if="store.getters.getDiscountedPrice" class="mb-0 fs-8">
                    <s>{{ $filters.getPriceFormatted(store.getters.getOldPrice) }}</s>
                </p>
            </div>
        </div>
        <div>
            <a class="btn btn-link px-0 d-flex align-items-center gap-2" :href="comebackUrl">{{ $t('global.comeback-to-site') }}
                <div class="d-inline-block bg-primary text-white rounded d-flex align-items-center justify-content-center p-1">
                    <font-awesome-icon icon="fa-light fa-xmark" />
                </div>
            </a>
        </div>
    </div>
</template>
