import { errorMiddleWare } from './errorMiddleware';

// http://pl.savicki:8000/api/configurator/facets/1/children?product_id=2229
// http://pl.savicki:8000/api/configurator/products/2229/root_facets/1/variants?options[]

export const getVariants = async ({ productId, facetId }) => {
    const result = await fetch(
        `${window.API_URL}/api/configurator/products/${productId}/root_facets/${facetId}/variants`
    );

    errorMiddleWare(result);

    const data = await result.json();

    return data;
};
