<script setup>
import { watch, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { apvizConfigurationLinks } from '@config/apviz.js';
import { createScriptLoader } from '@bigcommerce/script-loader';
import ConfiguratorViewSwitcher from '@pages/configurator/ConfiguratorViewSwitcher.vue';
import ConfiguratorTopLeft from '@pages/configurator/ConfiguratorTopLeft.vue';

const store = useStore();
const el = ref(null);
const loader = createScriptLoader();
window.apvizViewers = {};

const loadApvizScript = () => {
    const configuration = apvizConfigurationLinks[store.state.collection];

    return new Promise((resolve) => {
        loader
            .loadScript(configuration.src, {
                attributes: {
                    'data-apviz-callback': 'apvizShowcaseReady',
                },
            })
            .then(() => {
                resolve(true);
            });
    });
};

const updateViewer = async () => {
    const currentViewer = store.getters['apviz/getCurrentViewer'];

    if (
        !currentViewer?.viewer ||
        !store.getters.isInitialDataLoaded ||
        store.state.isLoadingCurrentConfiguration
    ) {
        console.log('1 warunek updateViewer');
        return false;
    }

    if (currentViewer.viewer.isDisposed) {
        console.log('reinitializing viewer');
        await store.dispatch('apviz/reinitializeViewer');
    } else {
        console.log('updating viewer with:', store.getters['apviz/getApvizNewConfiguration']);
        await currentViewer.viewer.update(store.getters['apviz/getApvizNewConfiguration']);
    }

    return true;
};

const fetchAndUpdateCameraState = async () => {
    await store.dispatch('apviz/getCameraState');
};

window.apvizShowcaseReady = async (showcase) => {
    await store.dispatch('apviz/initializeViewer', showcase);
};

watch(
    [
        () => store.getters['apviz/getApvizNewConfiguration'],
        () => store.getters['apviz/getCurrentViewer'],
    ],
    async () => {
        await updateViewer();

        window.apvizViewer = store.getters['apviz/getCurrentViewer']?.viewer;
    }
);

watch(
    () => store.state.collection,
    async (newCollection, oldCollection) => {
        await store.dispatch('apviz/removeViewer', oldCollection);
        store.commit('apviz/setView', 'onlyring');

        loadApvizScript();
    }
);

onMounted(() => {
    el.value.addEventListener('touchend', () => {
        fetchAndUpdateCameraState();
    });

    el.value.addEventListener('click', () => {
        fetchAndUpdateCameraState();
    });

    window.addEventListener('ConfiguratorSummaryLoaded', () => {
        store.dispatch('apviz/loadInitialCameraState');
    });
});
</script>

<template>
    <div :class="{ 'apviz-preview-container': true, [store.state.apviz.view]: true }">
        <div
            id="myLoader"
            class="w-100 h-100 d-flex align-items-center justify-content-center position-absolute z-2 bg-white"
        ></div>
        <div
            id="apviz-3d-viewer"
            ref="el"
            class="w-100 h-100"
            data-shepherd-key="apvizPreview"
        ></div>
        <ConfiguratorTopLeft />
        <ConfiguratorViewSwitcher />
    </div>
</template>
