<script setup>
import { ref, computed } from 'vue';
import Spinner from '@components/Spinner.vue';
import Engrave from '@components/options/Engrave.vue';

import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import { engraveOptionName } from '@config/engrave.js';
import { apvizDefaultShowcases } from '@config/apviz.js';

const store = useStore();
const { t } = useI18n();

const emits = defineEmits(['setViewpoint', 'collapseClicked']);

const isActive = computed(() => store.getters.isActiveFacetCollapse('engrave'));

const headerElement = ref(null);

const handleCollapse = () => {
    if (!store.getters.isActiveFacetCollapse('engrave')) {
        if (!['onlyring'].includes(store.state.apviz.view)) {
            store.commit('apviz/setView', 'onlyring');

            setTimeout(() => {
                emits('setViewpoint', apvizDefaultShowcases['engrave']);
            }, 0);
        }
    }

    store.commit('toggleFacetCollapse', 'engrave');
    emits('collapseClicked', headerElement.value);
};

const engraveHasValue = computed(() =>
    store.state.form.options[engraveOptionName]?.length > 0 ? t('global.yes') : null
);
const engraveValue = computed(() =>
    store.getters.getOptionValue(engraveOptionName)
        ? `(${store.getters.getOptionValue(engraveOptionName)})`
        : ''
);
</script>

<template>
    <div>
        <div ref="headerElement" class="facet-collapse-header grid" @click="handleCollapse">
            <div class="g-col-2">
                <div class="fs-8">
                    <div v-if="engraveHasValue" class="engraving-symbol" style="width: 42px; height: 42px">
                        {{ engraveHasValue }}
                    </div>
                    <div v-else class="engraving-symbol" style="width: 42px; height: 42px">
                        <!-- {{ $t('global.no') }} -->
                        <font-awesome-icon icon="fa-light fa-pen" size="xl" />
                    </div>
                </div>
            </div>
            <div class="g-col-8 text-center text-lg-start">
                <strong class="fs-6">{{
                    $t('components.configurator.engrave-collapse.text1')
                }}</strong>
                <span class="text-dark fs-8 ms-2">{{ engraveValue }}</span>
            </div>
            <div class="g-col-2 text-end">
                <span v-if="!isActive" class=""
                    ><font-awesome-icon icon="fa-light fa-circle-plus" size="xl"
                /></span>
                <span v-else class=""
                    ><font-awesome-icon icon="fa-solid fa-circle-minus" size="xl"
                /></span>
            </div>
        </div>
        <Transition name="max-height">
            <div v-if="isActive">
                <div class="py-3 bg-light">
                    <Suspense>
                        <slot name="collapseContent">
                            <Engrave />
                        </slot>

                        <template #fallback>
                            <Spinner />
                        </template>
                    </Suspense>
                </div>
            </div>
        </Transition>
    </div>
</template>
