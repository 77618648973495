export const spinners = {
    namespaced: true,
    state: () => ({
        spinners: []
    }),
    getters: {
        isActiveSpinner: state => spinner => state.spinners.includes(spinner)
    },
    mutations: {
        toggleSpinner(state, payload) {
            if (state.spinners.includes(payload)) {
                state.spinners = state.spinners.filter(spinner => spinner !== payload);
            } else {
                state.spinners.push(payload);
            }
        }
    }
}