<script setup>
import CollectionsGrid from '@pages/home/CollectionsGrid.vue';

const comebackUrl = window.API_URL;
</script>

<template>
    <div
        class="container mx-auto text-center home-container align-items-center justify-content-center p-3"
    >
        <div class="mx-auto mb-5" style="max-width: 500px">
            <p class="fs-8">
                <a :href="comebackUrl">{{ $t('global.comeback-to-site') }}</a>
            </p>

            <h1 class="mb-4 fs-4 fw-normal">
                {{ $t('home.text1') }}
            </h1>

            <h4 class="mb-4">
                {{ $t('home.text2') }}
            </h4>

            <p class="mb-4">
                {{
                    $t('home.text3', {
                        amount: 6,
                    })
                }}
            </p>

            <p>
                {{ $t('home.text4') }}
            </p>
        </div>

        <div class="mb-5">
            <CollectionsGrid />
        </div>
    </div>
</template>
