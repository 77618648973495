<script setup>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import ApvizPreview from '@pages/configurator/ApvizPreview.vue';
import ConfiguratorSummary from '@pages/configurator/ConfiguratorSummary.vue';
import ConfiguratorSummaryHeaderDesktop from '@components/configurator/ConfiguratorSummaryHeaderDesktop.vue';
import Spinner from '@components/Spinner.vue';
import ContentSpinner from '@components/ContentSpinner.vue';
import ConfiguratorSummaryFooter from '@components/configurator/ConfiguratorSummaryFooter.vue';
// import ShepherdTour from '../components/ShepherdTour.vue';

const store = useStore();
const route = useRoute();
const configuratorRef = ref(null);
const summaryRef = ref(null);

const comebackUrl = window.API_URL;
store.commit('setCollection', route.params.slug);
store.commit('setProductId', route.query.productId);

const targets = computed(() => ({
    configurator: configuratorRef.value,
    summary: summaryRef.value,
}));

// onMounted(() => {
//     store.commit('setConfiguratorSummaryLoaded', true);
// });

onMounted(() => {
    document.body.classList.add('overflow-hidden');
});


onBeforeUnmount(() => {
    document.body.classList.remove('overflow-hidden');
});

</script>

<template>
    <div id="configurator" ref="configuratorRef" class="bg-light shadow pt-4 py-lg-3 px-3 rounded w-100">
        <!-- <ShepherdTour /> -->
        <div class="grid h-100 align-items-center gap-0 gap-lg-4 gap-md-4">
            <div class="g-col-12 g-col-md-7 position-relative h-100">
                <Suspense>
                    <ApvizPreview />

                    <template #fallback>
                        <Spinner />
                    </template>
                </Suspense>
            </div>
            <div
                id="configurator-summary"
                ref="summaryRef"
                class="g-col-12 g-col-md-5 position-relative overflow-hidden d-flex flex-column"
            >
                <div class="px-lg-4">
                    <ConfiguratorSummaryHeaderDesktop />
                </div>

                <ContentSpinner
                    v-if="store.getters['spinners/isActiveSpinner']('configurator-summary-spinner')"
                />

                <div
                    id="configurator-summary-scrollable-container"
                    data-shepherd-key="configurator-summary"
                    class="px-lg-4 px-2 mb-lg-4"
                >
                    <!-- <ConfiguratorSummaryHeaderDesktop /> -->

                    <Suspense>
                        <ConfiguratorSummary />

                        <!-- <template #fallback>
                                <Spinner />
                        </template> -->
                    </Suspense>

                    
                </div>

                <ConfiguratorSummaryFooter />
            </div>
        </div>
        
        <div class="d-lg-none">
            <a :href="comebackUrl" class="btn btn-link position-fixed top-0 end-0 p-0 fs-8 px-3 d-flex align-items-center gap-2">
                {{ $t('global.comeback-to-site') }}
                <div class="d-inline-block bg-primary text-white rounded d-flex align-items-center justify-content-center p-1">
                    <font-awesome-icon icon="fa-light fa-xmark" />
                </div>
            </a>
        </div>

    </div>
</template>

<style>
.ps {
    /* width: 100%; */
    /* overflow-x: hidden; */

    height: calc(100% - 50px) !important;
}
</style>
