import { createApp } from 'vue';
import './style.css';
import App from './App.vue';
// import { createI18n } from 'vue-i18n';
import * as Sentry from '@sentry/vue';
// Import our custom CSS
import './scss/main.scss';

import '@config/global.js';

// Import all of Bootstrap's JS
import '@src/vendors/bootstrap';
import router from '@router/router.js';
import store from '@store/store.js';

import { getPriceFormatted } from '@utils/getPriceFormatted.js';

import VueGtag from 'vue-gtag-next';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCirclePlus,
    faCircleMinus,
    faBagShopping,
    faAngleRight,
    faAngleDown,
    faAngleUp,
    faAngleLeft,
    faRingDiamond,
    faHand,
    faBoxHeart,
    faCamera,
    faQuestion,
    faXmark,
    faFloppyDisk,
    faTriangleExclamation,
    faBell,
    faPen
} from '@fortawesome/pro-light-svg-icons';
import { faCircle, faCircleMinus as faCircleMinusSolid } from '@fortawesome/free-solid-svg-icons';

// lights
library.add(
    faCirclePlus,
    faCircleMinus,
    faBagShopping,
    faAngleLeft,
    faAngleRight,
    faAngleDown,
    faAngleUp,
    faRingDiamond,
    faHand,
    faBoxHeart,
    faCamera,
    faQuestion,
    faXmark,
    faFloppyDisk,
    faTriangleExclamation,
    faBell,
    faPen
);
// solid
library.add(faCircle, faCircleMinusSolid);

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

import { i18n } from './i18n/index.js';

// import VueSplide from '@splidejs/vue-splide';

const app = createApp(App);

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        app,
        environment: process.env.NODE_ENV,
        dsn: 'https://33cd2dd810b146d7ad5a4189114d0916@sentry.sylapi.com/6',
        integrations: [
            new Sentry.BrowserTracing({
                // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ['localhost', /^https:\/\/studio\.savicki\.pl/],
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
                maskAllInputs: false,
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
    });
}

app.config.errorHandler = (error) => {
    console.error(error);
    store.commit('setError', true);
};

app.use(router);
app.use(store);
app.use(PerfectScrollbar, {
    options: {
        suppressScrollX: true,
    },
});

// app.use(VueSplide, {
//     mo
// })
app.use(i18n);

app.use(VueGtag, {
    // useDebugger: true,
    property: {
        id: 'G-5ZQWYQY7WZ',
        params: {
            send_page_view: true,
        },
    },
});

app.use(VueTelInput, {
    mode: 'international',
    defaultCountry: i18n.global.locale.value?.split('-')[1].toUpperCase() || 'US',
    inputOptions: {
        placeholder: i18n.global.t('global.phone'),
    },
});

app.component('FontAwesomeIcon', FontAwesomeIcon);

app.config.globalProperties.$filters = {
    getPriceFormatted,
};

app.mount('#app');
