import store from '@store/store.js';

const options = {
    default: {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }
}

export const getPriceFormatted = (price) => {
    // Get the user's locale
    // const locale = navigator.language;
    const locale = window.LANGUAGE || 'en-GB';
    const currency = store.getters.getCurrency?.code || 'GBP';
    // const currency = window.CURRENCY || 'GBP';

    if (!price) {
        return null
    }

    // Return the price formatted in the user's locale
    return new Intl.NumberFormat(locale, {
        ...options.default,
        ...(options[locale] || {}),
        style: 'currency',
        currency: currency
    }).format(price);

    // return price;
}