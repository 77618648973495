<script setup>
import { ref, useAttrs } from 'vue';

const props = defineProps({
    placeholder: {
        type: String,
        required: true,
    },
    modelValue: {
        type: String,
        required: false,
        default: null,
    },
});

const attrs = useAttrs();
const emit = defineEmits(['update:modelValue']);

const allAttributes = ref({ ...props, ...attrs });
</script>

<template>
    <div class="form-floating mb-3">
        <input
            v-bind="allAttributes"
            class="form-control"
            :value="modelValue"
            @input="(e) => emit('update:modelValue', e.target.value)"
        />
        <label>{{ props.placeholder }}</label>
    </div>
</template>
