export const handleAddToCartInIframe = (html) => {
    window.parent.postMessage({ type: 'ADDED_TO_CART', data: { html }}, '*');
}

export const handleAddToCartOnSite = () => {
    const domainToCartLinkMap = {
        'savicki.pl': 'https://savicki.pl/zamowienie',
        'savicki.co.uk': 'https://savicki.com/checkout',
        'savicki.com': 'https://savicki.com/checkout',
        'savicki.cz': 'https://savicki.cz/objednavka',
        'savicki.sk': 'https://savicki.sk/objednavka',
        'savicki.bg': 'https://savicki.bg/porcki',
        'savicki.gr': 'https://savicki.gr/cart',
        'savicki.hu': 'https://savicki.hu/megrendeles',
        'savicki.de': 'https://savicki.de/bestellung-lieferung-und-zahlung',
        'savicki.ro': 'https://savicki.ro/comanda',
        'savicki.hr': 'https://savicki.hr/checkout',
        'savicki.com.ua': 'https://savicki.com.ua/zamovlennya',
        'pl': 'https://savicki.pl/zamowienie',
        'co.uk': 'https://savicki.com/checkout',
        'com': 'https://savicki.com/checkout',
        'cz': 'https://savicki.cz/objednavka',
        'sk': 'https://savicki.sk/objednavka',
        'bg': 'https://savicki.bg/porcki',
        'gr': 'https://savicki.gr/cart',
        'hu': 'https://savicki.hu/megrendeles',
        'de': 'https://savicki.de/bestellung-lieferung-und-zahlung',
        'ro': 'https://savicki.ro/comanda',
        'hr': 'https://savicki.hr/checkout',
        'com.ua': 'https://savicki.com.ua/zamovlennya',
    }

    const link = domainToCartLinkMap[window.domain] || domainToCartLinkMap['com'];

    window.location.href = link;
}