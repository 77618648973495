const fontsMap = {
    Anton: 'anton',
    'Bree Serif': 'bree',
    Charm: 'charm',
    Roboto: 'roboto',
};

export const availableSymbols = [
    '&#9825;',
    '&hearts;',
    '&#10084;',
    '&#10085;',
    '&#9892;',
    '&infin;',
    '&diams;',
];

export const engraveMaxLength = 20;

export const engraveOptionName = 'engrave';
export const engraveFontOptionName = 'engrave_font';

export const engraveHelper = (state, includeEngrave = true) => {
    // const obj = {
    //     fields: {
    //         view: state.apviz.view || 'onlyring',
    //     },
    // }

    let markingZoneEngraveName = 'engrave';
    // let fontKey = 'font-anton';

    // console.log(state);

    // TODO: make as default if all collection will have common engrave settings
    if (state.collection === 'thelightcollection') {
        markingZoneEngraveName =
            Number(state.current.configuration?.main_stone_mass?.facet.slug) >= 1.5
                ? 'engravebig'
                : 'engravesmall';
        //  fontKey = 'Anton Regular';
    }

    const availableFonts = state.current.options?.main_options_transformed?.engrave_font?.values;

    const choosenFont = availableFonts?.find(
        (value) => value.id === state.form.options.engrave_font
    );
    const defaultFont = availableFonts?.find((el) => el.id_default === true);

    const markingZones = {
        // Targets a specific marking zone via it's key.
        [markingZoneEngraveName]: {
            text: {
                value: includeEngrave ? state.form.options.engrave || '' : '',
                fontKey:
                    fontsMap[
                        choosenFont?.option_value.translation.label ||
                            choosenFont?.option_value.translation.name ||
                            ''
                    ] ||
                    fontsMap[
                        defaultFont?.option_value.translation.label ||
                            defaultFont?.option_value.translation.name ||
                            ''
                    ] ||
                    'anton',
                heightMode: 'font', // or "font"
                horizontalAlignment: 'center', // "left" or "right"
                verticalAlignment: 'middle', // "top", or "bottom"
                relief: {
                    direction: 'down', // or "up"
                    depth: 0.0005, // In meters.
                    angle: 90, // Between 1° and 90°.
                },
            },
        },
    };

    return markingZones;
};
