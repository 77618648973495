<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const handleClose = () => {
    store.dispatch('modal/deactivateModal');
};

const modalTitle = computed(() => store.state.modal?.title);
</script>

<template>
    <div id="modal" class="modal fade" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h5 v-if="modalTitle" class="modal-title">{{ modalTitle }}</h5>
                    </div>
                    <a href="#" @click="handleClose">
                        <font-awesome-icon icon="fal fa-xmark" size="lg" />
                    </a>
                </div>
                <div class="modal-body">
                    <component
                        :is="store.state.modal.contentComponent"
                        :data="store.state.modal.data"
                    />
                    <!-- <Suspense>
                    <component :is="store.getters['modal/contentComponent']"/>

                    <template #fallback>
                        <ContentSpinner />
                    </template>
                </Suspense> -->
                </div>
                <!-- <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div> -->
            </div>
        </div>
    </div>
</template>
