<script setup>
    import { useStore } from 'vuex';

    import { downloadFile } from "@utils/downloadFile.js";

    const store = useStore();

    const handleTakePhoto = async () => {
        const screenshot = await store.getters['apviz/getCurrentViewer'].viewer.captureImage({
                imageHeight: 1500,
                imageWidth: 1500,
            });

        const data = await screenshot.toDataUrl();

        downloadFile(data, 'SavickiConfigurator')
    }
</script>

<template>
    <div class="position-absolute top-0 start-0 px-3 py-2 d-flex gap-3 z-3">
        <div :class="{'engraving-symbol': true, 'is-active': store.getters['apviz/getApvizPickedView'] === 'hand' }" style="width:34px;height:34px;" @click="handleTakePhoto">
            <font-awesome-icon icon="fal fa-camera" />
        </div>
    </div>
</template>

<style lang="css">

.box-symbol {
    /* color: #fff; */
    border: 2px solid #d0d0d0;
    border-radius: 100%;

    width: 18px;
    height: 18px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.box-symbol.is-active {
    border-color: #000;
}
    
</style>