<script setup>
    import { computed } from "vue"
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';

    const store = useStore();
    const { t } = useI18n();

    const remainingOptions = computed(() => Object.keys(store.getters.getSummaryRemainingOptions)?.reduce((memo, slug) => ({...memo, [slug]: { _option: store.getters.getOption(slug), _value: store.getters.getOption(slug)?.values?.find(val => val.id === store.getters.getSummaryRemainingOptions[slug]) }}), {}));
</script>

<template>
    <div class="ps-5 fs-8 d-flex flex-column gap-3">
        <div v-for="(item, index) in remainingOptions" :key="index" class="d-flex justify-content-between align-items-center">
            <!-- <span>{{  }}:</span> -->
            <span>{{ item._option?.option?.translation.name || '' }}:</span>
            <strong>{{ item._value?.option_value?.translation.label || item._value?.option_value?.translation.name || t('global.yes') }}</strong>
        </div>
    </div>
</template>
