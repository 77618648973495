import { errorMiddleWare } from './errorMiddleware';

export const getCurrentConfiguration = async (productId) => {
    try {
        const result = await fetch(
            `${window.API_URL}/api/configurator/products/${productId}/facets`
        );

        errorMiddleWare(result);

        const data = await result.json();

        return data;
    } catch (err) {
        // console.info(err);
        // return data;
        return null;
    }
};
