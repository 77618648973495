<script setup>
import SelectWithArrows from '@components/interfaces/SelectWithArrows.vue';
import InputText from '@components/interfaces/InputText.vue';

import {
    availableSymbols,
    engraveMaxLength,
    engraveOptionName,
    engraveFontOptionName,
} from '@config/engrave.js';

import { computed, watch, ref, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const store = useStore();
const { t } = useI18n();

const inputComponent = ref(null);

// const availableFontsNew = store.getters.getAvailableFonts;

// console.log({ availableFontsNew });

const availableFonts = store.getters.getAvailableFonts;

const engraveOption = computed({
    get: () => store.state.form.options[engraveOptionName],
    set: (newValue) => store.commit('setFormOption', { name: engraveOptionName, value: newValue }),
});

const engraveFontOption = computed({
    get: () => store.state.form.options[engraveFontOptionName],
    set: (newValue) =>
        store.commit('setFormOption', { name: engraveFontOptionName, value: newValue }),
});

watch(
    () => store.state.form.options[engraveOptionName],
    (newValue) => {
        if (newValue) {
            if (!engraveFontOption.value) {
                engraveFontOption.value = availableFonts.find((el) => el.default === true).value;
            }
        } else {
            engraveFontOption.value = null;
        }
    }
);

const insertChar = (charToInsert) => {
    const inputElement = inputComponent.value.$el.querySelector('input');

    const cursorPosition = inputElement.selectionStart;
    engraveOption.value =
        engraveOption.value.slice(0, cursorPosition) +
        charToInsert +
        engraveOption.value.slice(cursorPosition);

    nextTick(() => {
        // Adjusting the cursor position after inserting a character
        inputElement.selectionStart = cursorPosition + charToInsert.length;
        inputElement.selectionEnd = cursorPosition + charToInsert.length;
    });
};

const handleSymbolClick = (symbol) => {
    if (engraveOption.value.length >= engraveMaxLength) {
        return;
    }

    const textarea = document.createElement('textarea');
    textarea.innerHTML = symbol;
    textarea.value;

    insertChar(textarea.value);
};
</script>

<template>
    <div>
        <div class="mb-3">
            <SelectWithArrows
                v-model="engraveFontOption"
                :options="availableFonts"
                option-label="title"
                value-key="value"
                :select-label="t('global.choose-style')"
            />
        </div>

        <div class="mb-3">
            <p class="fw-bold fs-7">
                {{ $t('components.options.engrave.text1') }}:
                <span class="fs-8 text-dark ms-2"
                    >({{ $t('components.options.engrave.text2', { chars: 20 }) }})</span
                >
            </p>

            <div class="mb-3">
                <InputText
                    id="option[engrave]"
                    ref="inputComponent"
                    v-model="engraveOption"
                    autocomplete="off"
                    name="option[engrave]"
                    :placeholder="t('components.options.engrave.text3')"
                    :maxlength="engraveMaxLength"
                />
            </div>

            <div v-if="store.getters.isEngraveFontEnabled">
                <p class="fw-bold fs-7">
                    {{ $t('components.options.engrave.text4') }}:
                    <span class="fs-8 text-dark ms-2"
                        >({{ $t('components.options.engrave.text5') }})</span
                    >
                </p>

                <div class="d-flex gap-3">
                    <div
                        v-for="(symbol, index) in availableSymbols"
                        :key="index"
                        class="engraving-symbol"
                        style="width: 34px; height: 34px"
                        @click="() => handleSymbolClick(symbol)"
                    >
                        <span v-html="symbol"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
