export const modal = {
    namespaced: true,
    state: () => ({
        isActive: false,
        contentComponent: null,
        title: '',
        data: {},
    }),
    getters: {
        isActiveModal: (state) => state.isActive,
        getContentComponent: (state) => state.contentComponent,
    },
    mutations: {
        setTitle(state, payload) {
            state.title = payload;
        },

        setData(state, payload) {
            state.data = payload;
        },
        // toggleModal(state, payload) {
        //     state.isActive = !state.isActive;

        //     if (payload.contentComponent) {
        //         state.contentComponent = payload.contentComponent;
        //     }
        // },
    },

    actions: {
        async activateModal(context, payload) {
            return new Promise((resolve) => {
                context.state.isActive = true;

                if (payload.title) {
                    // context.state.title = payload.title;
                    context.commit('setTitle', payload.title);
                }

                if (payload.data) {
                    // context.state.data = payload.data;
                    context.commit('setData', payload.data);
                }

                if (payload.contentComponent) {
                    context.state.contentComponent = payload.contentComponent;
                }

                const modalElement = document.querySelector('#modal');
                const modal = window.bootstrap.Modal.getOrCreateInstance(modalElement);

                const listener = () => {
                    resolve(true);
                };

                modalElement.addEventListener('shown.bs.modal', listener, { once: true });

                modal.show();
            });
        },

        async deactivateModal(context) {
            return new Promise((resolve) => {
                context.state.isActive = false;
                // context.state.title = '';
                context.state.contentComponent = null;
                // context.state.data = {};
                context.commit('setTitle', '');
                context.commit('setData', {});

                const modalElement = document.querySelector('#modal');
                const modal = window.bootstrap.Modal.getOrCreateInstance(modalElement);

                const listener = () => {
                    resolve(true);
                };

                modalElement.addEventListener('hidden.bs.modal', listener, { once: true });

                modal.hide();
            });
        },

        async switchModal(context, payload) {
            await context.dispatch('deactivateModal');

            context.dispatch('activateModal', payload);
        },
    },
};
