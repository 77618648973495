<script setup>

</script>

<template>
    <div class="spinner-container">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>

<style>
    .spinner-container {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        z-index: 1000;
        /* pointer-events: none; */

        background: rgba(255, 255, 255, 0.4);
    }
</style>