import thelightimage from '@assets/pages/home/thelightcollection.png';
import fairytaleimage from '@assets/pages/home/fairytalecollection.png';
import thejourneyimage from '@assets/pages/home/thejourneycollection.png';
import thisisloveimage from '@assets/pages/home/thisislovecollection.png';
import shareyourloveimage from '@assets/pages/home/shareyourlovecollection.png';
import dreamimage from '@assets/pages/home/dreamcollection.png';
import savickiringimage from '@assets/pages/home/savickiringcollection.png';
import pureimage from '@assets/pages/home/purecollection.png';

import thelightlogo from '@assets/collection-logotypes/thelight.webp';
import fairytalelogo from '@assets/collection-logotypes/fairytale.webp';
import thejourneylogo from '@assets/collection-logotypes/thejourney.webp';
import thisislovelogo from '@assets/collection-logotypes/thisislove.webp';
import shareyourlovelogo from '@assets/collection-logotypes/shareyourlove.webp';
import dreamlogo from '@assets/collection-logotypes/dream.webp';
import savickiringlogo from '@assets/collection-logotypes/savickiring.webp';
import purelogo from '@assets/collection-logotypes/pure.webp';

import { apvizConfigurationLinks } from '@config/apviz';

const images = {
    thelightcollection: {
        img: thelightimage,
        logo: thelightlogo,
        title: 'The Light Collection',
    },
    fairytalecollection: {
        img: fairytaleimage,
        logo: fairytalelogo,
        title: 'Fairytale Collection',
    },
    thejourneycollection: {
        img: thejourneyimage,
        logo: thejourneylogo,
        title: 'The Journey Collection',
    },
    shareyourlovecollection: {
        img: shareyourloveimage,
        logo: shareyourlovelogo,
        title: 'Share Your Love Collection',
    },
    thisislovecollection: {
        img: thisisloveimage,
        logo: thisislovelogo,
        title: 'This Is Love Collection',
    },
    dreamcollection: {
        img: dreamimage,
        logo: dreamlogo,
        title: 'Dream Collection',
    },
    savickiringcollection: {
        img: savickiringimage,
        logo: savickiringlogo,
        title: 'Savicki Ring Collection',
    },
    purecollection: {
        img: pureimage,
        logo: purelogo,
        title: 'Pure Collection',
    },
};

const items = Object.keys(apvizConfigurationLinks).map((slug) => ({
    slug,
    productId: apvizConfigurationLinks[slug].productId,
    ...images[slug],
}));

export default items;
