<script setup>
import items from './collectionsData';

// import { store } from '@store/store.js';
import { useRouter } from 'vue-router';

const router = useRouter();

const handleClick = ({ productId }) => {
    router.push({
        name: 'configurator',
        params: {
            productId: productId,
        },
    });
};
</script>

<template>
    <div class="grid gap-4">
        <div
            v-for="(item, index) in items"
            :key="index"
            class="g-col-6 g-col-lg-3 cursor-pointer d-flex flex-column align-items-center"
            @click="
                () => {
                    handleClick({ collection: item.slug, productId: item.productId });
                }
            "
        >
            <img
                :src="item.logo"
                :alt="item.title || 'Collection image'"
                class="d-lg-none"
                height="30"
            />
            <img
                :src="item.logo"
                :alt="item.title || 'Collection image'"
                :title="item.title || 'Collection image'"
                class="d-none d-lg-inline-block"
                height="35"
            />
            <img
                :src="item.img"
                :alt="item.title || 'Collection image'"
                :title="item.title || 'Collection image'"
                class="img-fluid"
            />
        </div>
    </div>
</template>
