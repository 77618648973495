import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import qs from 'qs';

const router = createRouter({
    history: createWebHistory(),
    routes,
    stringifyQuery: (query) => qs.stringify(query, { encode: false }),
});

export default router;
