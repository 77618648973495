import { engraveHelper } from '@config/engrave';
import { initializeLoader, reinitializeViewerPreviewElement } from '@utils/apviz';

export const apviz = {
    namespaced: true,
    state: () => ({
        viewers: {},
        view: 'onlyring',
        configuration: {},
        cameraState: '',
    }),
    getters: {
        getCurrentViewer: (state, getters, rootState) => state.viewers[rootState.collection],
        getViewer: (state) => (collection) => state.viewers[collection],

        getApvizNewConfiguration: (state, getters, rootState) => {
            const apvizConfiguration = {};

            const facetsConfiguration = Object.keys(state.configuration).reduce(
                (memo, key) => ({
                    ...memo,
                    [state.configuration[key].root_facet.aviz_slug]:
                        state.configuration[key].facet.aviz_slug,
                }),
                {}
            );

            apvizConfiguration.fields = {
                ...facetsConfiguration,
                view: state.view,
            };

            apvizConfiguration.markingZones = {
                ...engraveHelper(rootState, true),
            };

            // if (state.cameraState) {
            //     apvizConfiguration.camera = {...state.cameraState};
            // }

            return apvizConfiguration;
        },

        getApvizNewConfigurationWithDefaultValues: (state, getters, rootState) => {
            const apvizConfiguration = {};

            const facetsConfiguration = Object.keys(state.configuration).reduce(
                (memo, key) => ({
                    ...memo,
                    [state.configuration[key].root_facet.aviz_slug]:
                        state.configuration[key].facet.aviz_slug,
                }),
                {}
            );

            apvizConfiguration.fields = {
                ...facetsConfiguration,
                view: state.view,
            };

            apvizConfiguration.markingZones = {
                ...engraveHelper(rootState, false),
            };

            return apvizConfiguration;
        },

        getApvizPickedView: (state) => state.view || '',
        getCameraState: (state) => state.cameraState || {},
    },
    mutations: {
        setViewer: (state, { collection, viewer, showcase }) => {
            state.viewers[collection] = {
                viewer,
                showcase,
            };
        },

        setView: (state, view) => {
            state.view = view;
        },

        setConfiguration: (state, configuration) => {
            state.configuration = configuration;
        },

        setCameraState(state, cameraState) {
            state.cameraState = {
                type: cameraState.type,
                distance: Number(cameraState?.distance || 0),
                yaw: Number(cameraState?.yaw || 0),
                focalLength: Number(cameraState?.focalLength || 0),
                pitch: Number(cameraState?.pitch || 0),
                target: {
                    x: Number(cameraState?.target?.x || 0),
                    y: Number(cameraState?.target?.y || 0),
                    z: Number(cameraState?.target?.z || 0),
                },
            };
        },
    },

    actions: {
        async getCameraState(context) {
            const viewer = context.getters['getCurrentViewer']?.viewer;
            if (viewer) {
                const cameraState = await viewer.getCamera();
                context.commit('setCameraState', cameraState.camera);
            }
        },

        async loadInitialCameraState(context) {
            context.dispatch('changeCameraPosition', context.getters['getCameraState']);
        },

        async changeCameraPosition(context, cameraData) {
            if (!Object.keys(cameraData || {}).length) {
                return;
            }

            if (!cameraData.type) {
                cameraData.type = 'ORBITAL_FIXED_TARGET';
            }

            context.commit('setCameraState', cameraData);

            const viewer = context.getters['getCurrentViewer']?.viewer;
            if (viewer) {
                await viewer.update({ camera: context.getters.getCameraState });
            }
        },

        async initializeViewer(context, showcase) {
            const viewer = await showcase.createViewer({
                divId: 'apviz-3d-viewer',
            });

            await initializeLoader(viewer);

            context.commit('setViewer', {
                viewer,
                showcase,
                collection: context.rootState.collection,
            });

            return true;
        },

        async reinitializeViewer(context) {
            const currentViewer = context.state.viewers[context.rootState.collection];

            reinitializeViewerPreviewElement();

            // append viewer to fresh element
            const viewer = await currentViewer.showcase.createViewer({
                divId: 'apviz-3d-viewer',
            });
            // context.state.viewers[context.rootState.collection].viewer =
            //     await currentViewer.showcase.createViewer({
            //         divId: 'apviz-3d-viewer',
            //     });
            context.commit('setViewer', {
                viewer,
                showcase: currentViewer.showcase,
                collection: context.rootState.collection,
            });

            await initializeLoader(viewer);

            return true;
        },

        async removeViewer(context, collection) {
            const viewerObj = context.state.viewers[collection];

            if (!viewerObj) {
                return false;
            }

            await viewerObj.viewer.dispose();

            return true;
        },
    },
};
