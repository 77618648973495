import { errorMiddleWare } from './errorMiddleware';

export const getOptions = async (productId) => {
    try {
        const result = await fetch(
            `${window.API_URL}/api/configurator/products/${productId}/options`
        );

        errorMiddleWare(result);

        const data = await result.json();

        return data;
    } catch (err) {
        return null;
    }
};
