<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import { mainOptionsToHide, additionalOptionsToHide, optionsBlueprint } from '@config/options.js';

const store = useStore();

const mainOptionsToShow = computed(() =>
    Object.keys(store.state.current.options?.main_options_transformed || {})
        .filter((key) => !mainOptionsToHide.includes(key))
        .reduce(
            (memo, key) => ({
                ...memo,
                [key]: store.state.current.options.main_options_transformed[key],
            }),
            {}
        )
);
const additionalOptionsToShow = computed(() =>
    Object.keys(store.state.current.options.additional_options_transformed || {})
        .filter((key) => !additionalOptionsToHide.includes(key))
        .reduce(
            (memo, key) => ({
                ...memo,
                [key]: store.state.current.options.additional_options_transformed[key],
            }),
            {}
        )
);

const options = computed(() => ({ ...mainOptionsToShow.value, ...additionalOptionsToShow.value }));

// const activeOption = ref(Object.keys(options.value)[0]);
store.commit('toggleOptionCollapse', Object.keys(options.value)[0]);

const handleCollapse = (optionSlug) => {
    store.commit('toggleOptionCollapse', optionSlug);
};

// console.log({mainOptionsToShow, additionalOptionsToShow})
// console.log(optionsBlueprint[activeOption])
</script>

<template>
    <div>
        <div class="d-flex flex-wrap gap-3 mb-4">
            <div
                v-for="(item, optionSlug) in options"
                :key="optionSlug"
                :class="{
                    'option-badge': true,
                    'rounded-pill': true,
                    'is-active': store.getters.isActiveOptionCollapse(optionSlug),
                }"
                @click="() => handleCollapse(optionSlug)"
            >
                <span>
                    <font-awesome-icon icon="fa-solid fa-circle" />
                </span>
                <span>{{ item.option.translation.name }}</span>
            </div>
        </div>

        <component
            :is="
                optionsBlueprint[store.state.activeOptionCollapse]?.component ||
                optionsBlueprint['default'].component
            "
            :option="options[store.state.activeOptionCollapse]"
            :data="optionsBlueprint[store.state.activeOptionCollapse]?.data"
        />
    </div>
</template>
