import { errorMiddleWare } from './errorMiddleware';

export const getPrices = async ({ productId, options }) => {
    try {
        const result = await fetch(
            `${window.API_URL}/api/configurator/products/${productId}/prices${
                options ? `?${options}` : ''
            }`
        );

        errorMiddleWare(result);

        const data = await result.json();

        return data;
    } catch (err) {
        // console.info(err);
        return null;
    }
};
