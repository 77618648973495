import { errorMiddleWare } from './errorMiddleware';

export const isProductImage = async (productId) => {
    const result = await fetch(
        `${window.API_URL}/api/configurator/products/${productId}/image`
    ).catch();

    return result;

    // errorMiddleWare(result);

    // const data = await result.json();

    // return data;
};

export const saveProductImage = async (productId, base64image) => {
    const result = await fetch(`${window.API_URL}/api/configurator/products/${productId}/image`, {
        method: 'POST',
        body: new URLSearchParams({
            image: base64image,
        }),
    });

    errorMiddleWare(result);

    const data = await result.json();

    return data;
};
