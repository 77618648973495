/**
 * Retrieves a value from an object using a dot notation string.
 * 
 * @param {Object} obj - The source object.
 * @param {string} path - The dot notation string indicating the desired value's location.
 * @returns {any} The value from the object based on the dot notation string, or undefined if not found.
 */
export const getValueByDotNotation = (obj, path) => 
  path.split('.')                       // Split the path by '.' to get an array of keys.
    .reduce((accumulator, key) => 
      (accumulator && accumulator[key] !== undefined) // Check if the accumulator and its key exist.
        ? accumulator[key]                            // If so, return the value for that key.
        : undefined,                                  // Otherwise, return undefined.
      obj                                             // Starting point for the accumulator is the source object.
    );