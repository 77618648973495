<script setup>
const props = defineProps({
    productGroup: {
        type: Object,
        required: true,
    },
    rootFacet: {
        type: Object,
        required: true,
    },
});

const imageUrl = `/images/group_icons/${props.productGroup.facet.image_name}.webp`;
</script>

<template>
    <div>
        <div v-if="['main_stone_mass'].includes(props.rootFacet.slug)" class="fs-8">
            <div class="engraving-symbol" style="width: 42px; height: 42px">
                {{ props.productGroup.facet.slug }}ct
            </div>
        </div>
        <div v-else class="ratio ratio-1x1" style="width: 42px">
            <div>
                <img
                    :src="imageUrl"
                    alt="Group image"
                    title="Group image"
                    loading="lazy"
                    height="26"
                    class="group-image-circle-big is-active"
                />
            </div>
        </div>
    </div>
</template>
