<script setup>
import { watch, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { getVariants } from '@async/variants.js';

const props = defineProps({
    productGroup: {
        type: Object,
        required: true,
    },
});

const route = useRoute();
const variants = reactive({});

variants.value = await getVariants({
    productId: route.params.productId,
    facetId: props.productGroup.root_facet.id,
});

console.log('loaded variants init', variants.value);
</script>

<template>
    <div ref="groupCollapseDiv">
        <slot :variants="variants.value"></slot>
    </div>
</template>
