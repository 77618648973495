<script setup>

</script>

<template>
    <div class="d-flex w-100 h-100 align-items-center justify-content-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>