<script setup>
    const props = defineProps({
        productGroup: {
            type: Object,
            required: true
        }
    })
</script>

<template>
    <div class="ps-5 fs-8">
        <div class="d-flex justify-content-between align-items-center">
            <span>{{ $t('components.summary.facet.text1') }}:</span>
            <strong>{{ props.productGroup.facet.translation.name || props.productGroup.facet.slug || '' }}</strong>
        </div>
    </div>
</template>