<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const availableFonts = computed(() => store.getters.getAvailableFonts);

const engrave = computed(() => store.state.form.options.engrave);
const engraveFont = computed(
    () =>
        availableFonts.value?.find((el) => el.value === store.state.form.options.engrave_font)
            ?.title || availableFonts.value?.find((el) => el.is_default)?.title
);

console.log({ engraveFont });
</script>

<template>
    <div class="ps-5 fs-8 d-flex flex-column gap-3">
        <div class="d-flex justify-content-between align-items-center">
            <span>{{ $t('components.summary.engraving.text1') }}:</span>
            <strong v-if="engrave || engraveFont">Tak</strong>
            <strong v-else>{{ $t('global.lack') }}</strong>
        </div>

        <div v-if="engraveFont" class="d-flex justify-content-between align-items-center">
            <span>{{ $t('components.summary.engraving.text2') }}:</span>
            <strong>{{ engraveFont || '' }}</strong>
        </div>

        <div v-if="engrave" class="d-flex justify-content-between align-items-center">
            <span>{{ $t('components.summary.engraving.text3') }}:</span>
            <strong>{{ engrave || '' }}</strong>
        </div>
    </div>
</template>
