import pl from './pl-PL.json';
import cs from './cs-CZ.json';
import el from './el-GR.json';
import hu from './hu-HU.json';
import sk from './sk-SK.json';
import ro from './ro-RO.json';
import bg from './bg-BG.json';
import de from './de-DE.json';
import enGB from './en-GB.json';
import enUS from './en-US.json';
import hr from './hr-HR.json';

import { createI18n } from 'vue-i18n';

const translations = {
    'pl-PL': pl,
    'en-GB': enGB,
    'en-US': enUS,
    'cs-CZ': cs,
    'el-GR': el,
    'hu-HU': hu,
    'sk-SK': sk,
    'ro-RO': ro,
    'bg-BG': bg,
    'de-DE': de,
    'hr-HR': hr,
};

export const i18n = createI18n({
    legacy: false,
    locale: window.LANGUAGE,
    fallbackLocale: 'en-US',
    messages: translations,
    allowComposition: true,
});
