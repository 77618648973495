<script setup>
import items from '@pages/home/collectionsData';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed } from 'vue';

const store = useStore();
const router = useRouter();

const isActiveCollection = computed(
    () => (collectionSlug) => store.state.collection === collectionSlug
);

const handleClick = ({ productId }) => {
    router.push({
        name: 'configurator',
        params: {
            productId,
        },
    });
};
</script>

<template>
    <div class="grid">
        <div
            v-for="item in items"
            :key="item.slug"
            class="g-col-6 g-col-lg-4 d-flex flex-column align-items-center border rounded pt-2"
            :class="{
                'bg-dark': isActiveCollection(item.slug),
                'cursor-pointer': !isActiveCollection(item.slug),
            }"
            @click="
                () => {
                    handleClick({ collection: item.slug, productId: item.productId });
                }
            "
        >
            <img
                :src="item.logo"
                :alt="item.title || 'Collection image'"
                :title="item.title || 'Collection image'"
                class="d-lg-none"
                height="20"
            />
            <img
                :src="item.logo"
                :alt="item.title || 'Collection image'"
                :title="item.title || 'Collection image'"
                class="d-none d-lg-inline-block"
                height="25"
            />
            <img
                :src="item.img"
                :alt="item.title || 'Collection image'"
                :title="item.title || 'Collection image'"
                class="img-fluid"
            />
        </div>
    </div>
</template>
