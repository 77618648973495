import { defineAsyncComponent } from 'vue';
import { i18n } from '@src/i18n';

export const mainOptionsToHide = ['engrave', 'engrave_font', 'half_size'];

export const additionalOptionsToHide = [];

export const priceChangingOptions = ['fineness', 'platinization', 'hypoallergenic'];

export const optionsBlueprint = {
    fineness: {
        component: defineAsyncComponent(() => import('@components/options/Fineness.vue')),
    },
    size: {
        component: defineAsyncComponent(() => import('@components/options/Size.vue')),
    },
    platinization: {
        component: defineAsyncComponent(() => import('@components/options/AdditionalOption.vue')),
        data: {
            title: i18n.global.t('config.options.blueprint.platinization.title'),
            description: i18n.global.t('config.options.blueprint.platinization.description'),
        }
    },
    hypoallergenic: {
        component: defineAsyncComponent(() => import('@components/options/AdditionalOption.vue')),
        data: {
            title: i18n.global.t('config.options.blueprint.hypoallergenic.title'),
            description: i18n.global.t('config.options.blueprint.hypoallergenic.description'),
        }
    },
    default: {
        component: defineAsyncComponent(() => import('@components/options/AdditionalOption.vue'))
    }
}