import store from '@store/store';
import { defineAsyncComponent } from 'vue';
import { i18n } from '@src/i18n';

export const errorMiddleWare = (response) => {
    if (response.url.includes('/facets') && response.status === 404) {
        store.dispatch('modal/activateModal', {
            title: i18n.global.t('not-found.text1'),
            contentComponent: defineAsyncComponent(() => import('@components/modals/NotFound.vue')),
        });
    }

    if (!response.ok) {
        throw new Error(response.statusText);
        // console.error(response.statusText);
    }

    return response;
};
