export const apvizConfigurationLinks = {
    dreamcollection: {
        productId: '206843',
        src: 'https://public.apviz.io/showcases/U0hPVzp4bkU2UjVMRm5M/releases/UkVMUzpJWDY0Qm15RGtR/main.js',
        integrity: 'sha384-pQ87pxoThiAPCVqPvt5JncAhpZm8J78q7BK7d8bA2i/nTj/RgujzBIY/r+qunlye',
    },
    thelightcollection: {
        productId: '768',
        src: 'https://public.apviz.io/showcases/U0hPVzpaa3hHcmJ1R0w3/releases/UkVMUzpXbU5ndWhINk40/main.js',
        integrity: 'sha384-z/7UtfOv6zcgSWVkMnLe1o3LJ7pintCOl4sfG98u1MTCZ7/Q10GtortTM4HzE4WF',
    },
    thejourneycollection: {
        productId: '12064',
        src: 'https://public.apviz.io/showcases/U0hPVzo3MnFTaXp1RFVY/releases/UkVMUzpFcFp2WGdhV0o2/main.js',
        integrity: 'sha384-eCwX49mt0fPzxBxujgsjQZLnJBI94i6DQiqhmxOcj+0d1i20WNUQ4hH7sixuL6CA',
    },
    shareyourlovecollection: {
        productId: '2229',
        src: 'https://public.apviz.io/showcases/U0hPVzo5cFhQMHNaZFBu/releases/UkVMUzpsd2U0OFNGendJ/main.js',
        integrity: 'sha384-dp0bBTNIirwbGp3qNMtHSwrS30nkWSUhnMmf9cHn4CrdkJzXcs02KKHy8c9/S+nr',
    },
    fairytalecollection: {
        productId: '126',
        src: 'https://public.apviz.io/showcases/U0hPVzpTeXlBNklGUGVP/releases/UkVMUzoxSVNyTW1kdXpJ/main.js',
        integrity: 'sha384-BTRuNJerUQ045AOe2K1K7ZbVei08+Dp7DQ+Vap15Im4J794cMB2cQU/Z7SNFO7jx',
    },
    thisislovecollection: {
        productId: '2958',
        src: 'https://public.apviz.io/showcases/U0hPVzo4RERtTExvTTBK/releases/UkVMUzp4MFZkMWZ3VHF2/main.js',
        integrity: 'sha384-1fcHRucuuA9ABmYvXISMivHfSAYpJm+GxJf2s3neYPDYX/kvSjckBoyDnZ6BOjjH',
    },
    //   purecollection: {
    //     productId: '234729',
    //     src: 'https://public.apviz.io/showcases/U0hPVzplR1g4QzZUYVFB/releases/UkVMUzptalR6bzhDS2Y3/main.js'
    //   }
};

const boxesDefault = {
    type: 'ORBITAL_FIXED_TARGET',
    distance: 6.14367,
    focalLength: 131,
    pitch: -10.4,
    yaw: 278.9,
    target: {
        x: 0.0001,
        y: 0.07466,
        z: 0.00209,
    },
};

export const apvizDefaultShowcases = {
    onlyring: {
        type: 'ORBITAL_FIXED_TARGET',
        distance: 1.56462,
        focalLength: 131,
        pitch: -31.2,
        target: { x: 0, y: 0.02579, z: 0 },
        yaw: 298.2,
    },
    whitebox: {
        ...boxesDefault,
    },
    redbox: {
        ...boxesDefault,
    },
    blackbox: {
        ...boxesDefault,
    },
    hand: {
        type: 'ORBITAL_FIXED_TARGET',
        distance: 8.8787,
        focalLength: 131,
        pitch: -37.6,
        target: {
            x: 0.0001,
            y: 0.07466,
            z: 0.00209,
        },
        yaw: 313.1,
    },
    screenshot: {
        type: 'ORBITAL_FIXED_TARGET',
        distance: 0.8,
        yaw: 270,
        pitch: -102,
        focalLength: 100,
        target: { x: 0, y: 0.02579, z: 0 },
    },
    engrave: {
        type: 'ORBITAL_FIXED_TARGET',
        distance: 1,
        focalLength: 131,
        pitch: -72.2,
        target: { x: 0, y: 0.02579, z: 0 },
        yaw: 270.3,
    },
};
