import { errorMiddleWare } from './errorMiddleware';

export const addToCart = async (configuration) => {
    const result = await fetch(`${window.API_URL}/api/cart/items`, {
        method: 'POST',
        credentials: 'include',
        body: new URLSearchParams(configuration),
    });

    errorMiddleWare(result);

    // const data = await result.json();

    return result;
};
