<script setup>
import { useI18n } from 'vue-i18n';

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    slug: {
        type: String,
        required: true,
    },
});

const emits = defineEmits(['changeClicked']);

const { t } = useI18n();

const handleChange = () => {
    emits('changeClicked', props.slug);
};
</script>

<template>
    <div>
        <div class="d-flex align-items-center justify-content-between mb-3">
            <p class="mb-0 fw-bold">
                {{ props.title || '' }}
            </p>

            <p class="mb-0 fs-8">
                <a href="#" @click="handleChange">{{ t('global.change') }}</a>
            </p>
        </div>

        <div>
            <slot name="content"></slot>
        </div>
    </div>
</template>
