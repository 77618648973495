import { defineAsyncComponent } from "vue";
import { i18n } from "@src/i18n";

export default {
    metal: {
        component: defineAsyncComponent(() => import('@components/facets/CircleImages.vue')),
        // component: Metal,
        title: i18n.global.t('config.facets-blueprints.metal.title')
    },
    mainmetal: {
        component: defineAsyncComponent(() => import('@components/facets/CircleImages.vue')),
        // component: Metal,
        title: ''
    },
    crownmetal: {
        component: defineAsyncComponent(() => import('@components/facets/CircleImages.vue')),
        // component: Metal,
        title: ''
    },
    stone: {
        component: defineAsyncComponent(() => import('@components/facets/CircleImages.vue')),
        // component: Metal,
        title: i18n.global.t('config.facets-blueprints.stone.title')
    },
    sidestone: {
        component: defineAsyncComponent(() => import('@components/facets/CircleImages.vue')),
        // component: Metal,
        title: i18n.global.t('config.facets-blueprints.sidestone.title')
    },
    sidestone1: {
        component: defineAsyncComponent(() => import('@components/facets/CircleImages.vue')),
        // component: Metal,
        title: ''
    },
    sidestone2: {
        component: defineAsyncComponent(() => import('@components/facets/CircleImages.vue')),
        // component: Metal,
        title: i18n.global.t('config.facets-blueprints.sidestone2.title')
    },
    ministone: {
        component: defineAsyncComponent(() => import('@components/facets/CircleImages.vue')),
        // component: Metal,
        title: ''
    },
    cut: {
        component: defineAsyncComponent(() => import('@components/facets/CircleImages.vue')),
        // component: Metal,
        title: i18n.global.t('config.facets-blueprints.cut.title')
    },
    main_stone_mass: {
        component: defineAsyncComponent(() => import('@components/facets/MainStoneMass.vue')),
        title: i18n.global.t('config.facets-blueprints.main-stone-mass.title')
    },
    default: {
        // component: Metal,
        component: defineAsyncComponent(() => import('@components/facets/Default.vue')),
        title: 'facet.root_group_name'
    }
}