export const initializeLoader = async (viewer) => {
    const myLoader = document.querySelector('#myLoader');
    await viewer.addUpdatingListener((progress) => {
        myLoader.innerHTML = `${progress} %`;

        myLoader.classList.remove('d-none');
        if (progress == 100) {
            myLoader.classList.add('d-none');
        }
    });
};

export const reinitializeViewerPreviewElement = async () => {
    document.querySelector('#apviz-3d-viewer').remove();

    // Delete viewer from DOM and recreate fresh element
    let div = document.createElement('div');
    div.id = 'apviz-3d-viewer';
    div.classList.add('w-100', 'h-100', 'z-0', 'position-relative');
    document.querySelector('.apviz-preview-container').append(div);
};
