<script setup>
import { ref, computed } from 'vue';
import Spinner from '@components/Spinner.vue';
import Collection from '@components/options/Collection.vue';

import collectionsData from '@pages/home/collectionsData.js';

import { useStore } from 'vuex';

const store = useStore();

const emits = defineEmits(['setViewpoint', 'collapseClicked']);

const headerElement = ref(null);

const isActive = computed(() => store.getters.isActiveFacetCollapse('collection'));

const handleCollapse = () => {
    store.commit('toggleFacetCollapse', 'collection');
    emits('collapseClicked', headerElement.value);
};

const getImage = computed(
    () => collectionsData.find((el) => el.slug === store.state.collection)?.img
);
</script>

<template>
    <div>
        <div ref="headerElement" class="facet-collapse-header grid" @click="handleCollapse">
            <div class="g-col-2">
                <div class="fs-8">
                    <div class="engraving-symbol" style="width: 42px; height: 42px">
                        <img
                            :src="getImage"
                            alt="Collection image"
                            title="Collection image"
                            class="img-fluid"
                        />
                    </div>
                </div>
            </div>
            <div class="g-col-8 text-center text-lg-start">
                <strong class="fs-6">{{
                    $t('components.configurator.collection-collapse.text1')
                }}</strong>
            </div>
            <div class="g-col-2 text-end">
                <span v-if="!isActive" class=""
                    ><font-awesome-icon icon="fa-light fa-circle-plus" size="xl"
                /></span>
                <span v-else class=""
                    ><font-awesome-icon icon="fa-solid fa-circle-minus" size="xl"
                /></span>
            </div>
        </div>
        <div v-if="isActive">
            <div class="py-3 bg-light">
                <Suspense>
                    <slot name="collapseContent">
                        <Collection />
                    </slot>

                    <template #fallback>
                        <Spinner />
                    </template>
                </Suspense>
            </div>
        </div>
    </div>
</template>
