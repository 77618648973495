<script setup>
import { computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import SummaryItem from '@components/configurator/SummaryItem.vue';
import Facet from '@components/summary/Facet.vue';
import RemainingOptions from '@components/summary/RemainingOptions.vue';
import Engraving from '@components/summary/Engraving.vue';

const store = useStore();
const { t } = useI18n();

const currentConfiguration = computed(() =>
    Object.keys(store.getters.getCurrentConfiguration)
        .filter((key) => store.getters.getCurrentConfiguration[key].quantity_for_root_facet)
        .reduce((memo, key) => ({ ...memo, [key]: store.getters.getCurrentConfiguration[key] }), {})
);

const realisationTime = computed(() => store.getters.getRealisationTime);

const isAddingToCart = computed(() => store.state.isAddingToCart);

const handleChangeClick = (slug) => {
    store.commit('disactivateAllFacetCollapses');

    if (!store.getters.isActiveFacetCollapse(slug)) {
        store.commit('toggleFacetCollapse', slug);
    }

    window.bootstrap.Offcanvas.getInstance(document.querySelector('#summary-offcanvas')).hide();
};

const handleAddToCart = async () => {
    store.dispatch('addToCart');
};

const handleSaveConfiguration = () => {
    store.dispatch('modal/activateModal', {
        title: t('components.configurator.summary-offcanvas-content.text9'),
        contentComponent: defineAsyncComponent(
            () => import('@components/modals/SaveConfiguration.vue')
        ),
    });
};
</script>

<template>
    <div class="bg-white rounded p-4 mb-3">
        <div class="d-flex justify-content-between align-items-center">
            <p class="mb-0 fw-bold">
                {{ $t('components.configurator.summary-offcanvas-content.text1') }}:
            </p>

            <div class="d-flex flex-column align-items-center gap-0">
                <p class="mb-0 fs-2 fw-bold lh-1">
                    {{ $filters.getPriceFormatted(store.getters.getCurrentPrice) }}
                </p>
                <div class="d-flex justify-content-between align-items-center gap-3">
                    <p
                        v-if="store.getters.getDiscountedPrice"
                        class="mb-0 text-danger fs-7 fw-bold"
                    >
                        {{ $filters.getPriceFormatted(store.getters.getDiscountedPrice) }}
                    </p>
                    <p v-if="store.getters.getDiscountedPrice" class="mb-0 fs-7">
                        <s>{{ $filters.getPriceFormatted(store.getters.getOldPrice) }}</s>
                    </p>
                </div>
            </div>
        </div>

        <template v-if="realisationTime">
            <hr />

            <p class="d-flex gap-3 align-items-center mb-0 fs-7">
                <span class="text-success"><font-awesome-icon icon="fa-solid fa-circle" /></span>
                <span>{{
                    $t('components.configurator.summary-offcanvas-content.text2', {
                        time: realisationTime,
                    })
                }}</span>
            </p>
        </template>

        <hr />

        <div class="d-flex align-items-center mb-3 fs-7">
            <a class="btn btn-lg btn-outline-primary w-100" href="#" @click="handleSaveConfiguration">{{
                $t('components.configurator.summary-offcanvas-content.text3')
            }}</a>
            <!-- <a href="#">{{ $t('components.configurator.summary-offcanvas-content.text4') }}</a> -->
        </div>

        <button
            class="btn btn-primary btn-lg w-100 rounded d-flex justify-content-center gap-3 align-items-center"
            :disabled="isAddingToCart"
            @click="handleAddToCart"
        >
            <font-awesome-icon icon="fa-light fa-bag-shopping" size="xl" />
            <span>{{ $t('components.configurator.summary-offcanvas-content.text5') }}</span>
        </button>
    </div>

    <p class="text-white mb-3 fw-bold">
        {{ $t('components.configurator.summary-offcanvas-content.text6') }}
    </p>

    <div class="bg-white p-3 rounded">
        <!-- FACETS CONFIGURATION -->
        <div v-for="(item, key) in currentConfiguration" :key="`summary_${key}`" class="">
            <SummaryItem
                :title="item.root_facet.translation.name"
                :slug="key"
                @change-clicked="handleChangeClick"
            >
                <template #content>
                    <!-- <component :is=""></component> -->
                    <Facet :product-group="item" />
                </template>
            </SummaryItem>

            <hr />
        </div>

        <!-- ENGRAVE -->
        <div>
            <SummaryItem
                :title="$t('components.configurator.summary-offcanvas-content.text7')"
                slug="engrave"
                @change-clicked="handleChangeClick"
            >
                <template #content>
                    <Engraving />
                </template>
            </SummaryItem>

            <hr />
        </div>

        <!-- OPTIONS CONFIGURATION -->
        <div>
            <SummaryItem
                :title="$t('components.configurator.summary-offcanvas-content.text8')"
                slug="additional_options"
                @change-clicked="handleChangeClick"
            >
                <template #content>
                    <RemainingOptions />
                </template>
            </SummaryItem>
        </div>
    </div>
</template>
