<script setup>
import { ref, computed, watch } from 'vue';
import { getValueByDotNotation } from '@utils/getValueByDotNotation.js';
import { useI18n } from 'vue-i18n';

const props = defineProps({
    options: {
        type: Array,
        required: true,
    },
    value: {
        type: String,
        required: false,
        default: null,
    },
    optionLabel: {
        type: String,
        required: true,
    },
    valueKey: {
        type: String,
        required: false,
        default: 'id',
    },
    modelValue: {
        type: String,
        required: false,
        default: null,
    },
    labelPattern: {
        type: String,
        required: false,
        default: null,
    },
    selectLabel: {
        type: String,
        required: false,
        default: null,
    },
});

const emit = defineEmits(['change', 'update:modelValue']);
const { t } = useI18n();

const isOpened = ref(false);

const toggleOpen = () => {
    isOpened.value = !isOpened.value;
};

const handleSelect = (selectedOption) => {
    toggleOpen();

    const selectedValue = getValueByDotNotation(selectedOption, props.valueKey || 'id');

    if (props.modelValue !== selectedValue) {
        emit('update:modelValue', selectedValue);
    }
};

const handleOutsideClick = (e) => {
    if (!e.target.closest('.select-with-arrows-component')) {
        isOpened.value = false;
    }
};

const handleEscape = (e) => {
    if (e.key === 'Escape') {
        isOpened.value = false;
    }
};

const handleLabelDisplay = (value) => {
    if (props.labelPattern) {
        return props.labelPattern.replaceAll('{value}', value || '');
    }

    return value;
};

const displayValue = computed(() => {
    const value = props.options.find(
        (option) => getValueByDotNotation(option, props.valueKey || 'id') === props.modelValue
    );

    return (
        handleLabelDisplay(getValueByDotNotation(value, props.optionLabel || 'label')) ||
        props.selectLabel ||
        t('global.choose')
    );
});

const getValueIndex = () => {
    console.log(props.options);
    console.log(props.valueKey);
    console.log(props.modelValue);

    return props.options.findIndex(
        (option) => getValueByDotNotation(option, props.valueKey || 'id') === props.modelValue
    );
};

const selectedIndex = ref(getValueIndex());
const isFirstOptionSelected = computed(() => selectedIndex.value <= 0);
const isLastOptionSelected = computed(() => selectedIndex.value === props.options.length - 1);

watch(
    () => props.modelValue,
    () => {
        // console.log({newValue, val: getValueIndex()});
        // selectedIndex.value = '';
        selectedIndex.value = getValueIndex();
    }
);

const handlePrevArrowClick = () => {
    if (isFirstOptionSelected.value) {
        return;
    }

    const prevOption = props.options[selectedIndex.value - 1];

    emit('update:modelValue', getValueByDotNotation(prevOption, props.valueKey || 'id'));
};

const handleNextArrowClick = () => {
    if (isLastOptionSelected.value) {
        return;
    }

    const nextOption = props.options[selectedIndex.value + 1];

    emit('update:modelValue', getValueByDotNotation(nextOption, props.valueKey || 'id'));
};

window.addEventListener('click', handleOutsideClick);
window.addEventListener('keydown', handleEscape);
</script>

<template>
    <div class="d-flex align-items-center gap-3 justify-content-center">
        <div
            class="engraving-symbol"
            style="width: 34px; height: 34px"
            :disabled="isFirstOptionSelected"
            @click="handlePrevArrowClick"
        >
            <font-awesome-icon icon="fa-light fa-angle-left" size="lg" class="cursor-pointer" />
        </div>
        <div class="select-with-arrows-component">
            <div class="select-with-arrows-header rounded-pill" @click="toggleOpen">
                {{ displayValue }}
            </div>
            <div v-if="isOpened" class="position-relative">
                <div class="select-with-arrows-dropdown">
                    <div class="overflow-y-auto">
                        <div
                            v-for="(option, index) in options"
                            :key="index"
                            class="select-with-arrows-option"
                            @click="() => handleSelect(option)"
                        >
                            {{
                                handleLabelDisplay(
                                    getValueByDotNotation(option, props.optionLabel || 'label')
                                ) || ''
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="engraving-symbol"
            style="width: 34px; height: 34px"
            :disabled="isLastOptionSelected"
            @click="handleNextArrowClick"
        >
            <font-awesome-icon icon="fa-light fa-angle-right" size="lg" class="cursor-pointer" />
        </div>
    </div>
</template>
