import { errorMiddleWare } from './errorMiddleware';

export const getRealisationTime = async ({ productId, options }) => {
    const response = await fetch(
        `${window.API_URL}/api/product/availability_status?product_id=${productId}${
            options ? `&${options}` : ''
        }`
    );

    errorMiddleWare(response);

    const data = await response.json();

    return data;
};
