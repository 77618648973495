// window.domain = location.hostname.match(/^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/)?.[1];

window.domain = location.hostname.split('.').at(-1);

// window.domain = 'bg';

const domainsMap = {
    localhost: {
        locale: 'pl-PL',
        market: 4,
        api: 'http://pl.savicki:8000',
    },
    pl: {
        locale: 'pl-PL',
        market: 4,
        api: 'https://savicki.pl',
    },
    'co.uk': {
        locale: 'en-GB',
        market: 3,
        api: 'https://savicki.com',
    },
    com: {
        locale: 'en-US',
        market: 10,
        api: 'https://savicki.com',
    },
    cz: {
        locale: 'cs-CZ',
        market: 5,
        api: 'https://savicki.cz',
    },
    gr: {
        locale: 'el-GR',
        market: 15,
        api: 'https://savicki.gr',
    },
    hu: {
        locale: 'hu-HU',
        market: 9,
        api: 'https://savicki.hu',
    },
    sk: {
        locale: 'sk-SK',
        market: 6,
        api: 'https://savicki.sk',
    },
    ro: {
        locale: 'ro-RO',
        market: 8,
        api: 'https://savicki.ro',
    },
    bg: {
        locale: 'bg-BG',
        market: 12,
        api: 'https://savicki.bg',
    },
    de: {
        locale: 'de-DE',
        market: 7,
        api: 'https://savicki.de',
    },
    hr: {
        locale: 'hr-HR',
        market: 14,
        api: 'https://savicki.hr',
    },
};

// const domains = {
//     pl: 4,
//     "co.uk": 3,
//     'com': 10,
//     'cz': 5,
//     'gr': 15,
//     'hu': 9,
//     'sk': 6,
//     'ro': 8,
//     'bg': 12,
//     'de': 7,
//     'hr': 14,
// }

// const APIS = {
//     localhost: 'http://pl.savicki:8000',
//     pl: 'https://savicki.pl',
//     'co.uk': 'https://savicki.com',
//     'com': 'http://com.savicki:8000',
//     'cz': 'https://savicki.cz',
//     'gr': 'https://savicki.gr',
//     'hu': 'https://savicki.hu',
//     'sk': 'https://savicki.sk',
//     'ro': 'https://savicki.ro',
//     'bg': 'https://savicki.bg',
//     'de': 'https://savicki.de',
//     'hr': 'https://savicki.hr',
// }

window.MARKET_ID = domainsMap[window.domain]?.market || domainsMap['com'].market;

window.LANGUAGE = domainsMap[window.domain]?.locale || domainsMap['com'].locale;

window.API_URL = domainsMap[window.domain]?.api || domainsMap['com'].api;
