import { createStore } from 'vuex';
import { getCurrentConfiguration } from '@async/currentConfiguration';
import { getPrices } from '@async/prices';
import { getOptions } from '@async/options';
import { saveProductImage, isProductImage } from '@async/saveProductImage';
import { addToCart } from '@async/addToCart';
import { getRealisationTime } from '@async/realisationTime';

import { summaryRemainingOptions } from '@config/summary';
import { apvizDefaultShowcases } from '@config/apviz.js';
import { handleAddToCartInIframe, handleAddToCartOnSite } from '../utils/handleAddToCart';

// modules
import { spinners } from './modules/spinners';
import { apviz } from './modules/apviz';
import { modal } from './modules/modal';

import qs from 'qs';

import { i18n } from '@src/i18n';
import * as Sentry from '@sentry/vue';
// import ApvizConfiguration from "@config/apviz.js";

const store = createStore({
    state() {
        return {
            collection: '',
            productId: '',
            isAddingToCart: false,
            current: {
                configuration: {
                    // /api/configurator/products/{productId}
                },
                price: {
                    // /api/configurator/products/{productId}/prices?options[]
                },
                // currency: {
                //     // /api/configurator/products/{productId}/prices?options[]
                // },
                options: {
                    // /api/configurator/products/{productId}/options
                },
                realizationTime: {
                    // /api/product/availability_status?product_id={productId}&options[]
                },
            },
            form: {
                // modified by user
                options: {},
            },
            isLoadingCurrentConfiguration: true,
            activeFacetCollapses: [
                'metal'
                // currently active facet collapses
            ],
            activeOptionCollapse: null,
            errors: {
                // error codes
            },
            isError: false,
        };
    },
    getters: {
        getCurrentConfiguration: (state) => state.current.configuration,
        getPriceDifference: (state) => (variantPrice) =>
            Number(variantPrice) - Number(state.current.price?.price?.value),
        getModifiedOptionsWithRequestStructure: (state) =>
            Object.keys(state.form.options)
                .map((optionName) => `option[${optionName}]=${state.form.options[optionName]}`)
                .join('&'),
        getCurrentPrice: (state) => state.current.price.price?.value || null,
        getDiscountedPrice: (state) => {
            const currentPrice = state.current.price.price?.value || null;
            const oldPrice = state.current.price.old_price?.value || null;

            if (currentPrice && oldPrice) {
                return Number(currentPrice) < Number(oldPrice)
                    ? Number(oldPrice) - Number(currentPrice)
                    : null;
            }

            return null;
        },
        getOldPrice: (state) => state.current.price.old_price?.value || null,
        getCurrency: (state) => state.current.price.currency || null,
        getAvailableFonts: (state) =>
            state.current.options?.main_options_transformed?.engrave_font?.values?.map(
                (fontOptionValue) => {
                    return {
                        value: fontOptionValue.id,
                        title: fontOptionValue.option_value?.translation?.name || '',
                        font_family: fontOptionValue.option_value?.translation?.name || '',
                        default: fontOptionValue.is_default,
                    };
                }
            ),
        isEngraveFontEnabled: (state) =>
            state.current.options?.main_options_transformed?.engrave_font || false,
        areAdditionalOptionsEnabled: (state) =>
            state.current.options?.main_options_transformed?.fineness ||
            state.current.options?.main_options_transformed?.size ||
            state.current.options?.additional_options_transformed ||
            false,
        getOptionValue: (state) => (optionName) => state.form.options[optionName] || null,

        showEngraveModule: (state) =>
            state.current.options?.main_options_transformed.engrave || false,
        showAdditionalOptionsModule: (state) =>
            state.current.options?.main_options_transformed?.fineness ||
            state.current.options?.main_options_transformed?.size ||
            state.current.options?.additional_options_transformed ||
            false,

        isActiveFacetCollapse: (state) => (facetSlug) =>
            state.activeFacetCollapses.includes(facetSlug),
        isActiveOptionCollapse: (state) => (optionId) => state.activeOptionCollapse === optionId,

        getSummaryRemainingOptions: (state) =>
            Object.keys(state.form.options || {})
                .filter((optionName) => summaryRemainingOptions.includes(optionName))
                .reduce(
                    (memo, optionName) => ({
                        ...memo,
                        [optionName]: state.form.options[optionName],
                    }),
                    {}
                ),

        getOption: (state) => (optionSlug) =>
            ({
                ...(state.current.options?.main_options_transformed || {}),
                ...(state.current.options?.additional_options_transformed || {}),
            })[optionSlug],

        isInitialDataLoaded: (state) => !!Object.keys(state.current.configuration).length,

        getAddToCartProductConfiguration: (state) => {
            const obj = {
                product_id: state.productId,
                // from_configurator: 1,
                is_configurator: 1,
                quantity: 1,
                days_order: 1,
                ...Object.keys(state.form.options).reduce(
                    (memo, optionKey) => ({
                        ...memo,
                        [`option[${optionKey}]`]: state.form.options[optionKey],
                    }),
                    {}
                ),
            };

            return obj;
        },
        getShareableLink: (state, getters) => {
            const options = Object.keys(state.form.options).reduce(
                (memo, optionKey) => ({
                    ...memo,
                    [`option[${optionKey}]`]: state.form.options[optionKey],
                }),
                {}
            );
            const apvizView = getters['apviz/getApvizPickedView'];
            const cameraState = getters['apviz/getCameraState'];

            return { ...options, apvizView, cameraState };
        },
        getRealisationTime: (state) => state.current.realizationTime?.translation?.name,
    },
    mutations: {
        setCollection(state, collection) {
            state.collection = collection;
        },
        setProductId(state, productId) {
            state.productId = productId;
        },
        setIsAddingToCart(state, isAddingToCart) {
            state.isAddingToCart = isAddingToCart;
        },
        setCurrentConfiguration(state, currentConfiguration) {
            state.current.configuration = currentConfiguration;
        },
        setPrices(state, prices) {
            state.current.price = prices;
        },
        setOptions(state, options) {
            state.current.options = options;
        },
        cleanFormOptionsThatDontExist(state, options) {
            Object.keys(state.form.options).forEach((optionName) => {
                if (
                    !options.main_options_transformed?.[optionName] &&
                    !options.additional_options_transformed?.[optionName]
                ) {
                    delete state.form.options[optionName];
                }
            });
        },
        setDefaultFormOptions(state, options) {
            // set fineness (fineness is required)
            if (options?.main_options_transformed?.fineness && !state.form.options.fineness) {
                const defaultFineness = options?.main_options_transformed.fineness.values.find(
                    (optionValue) => optionValue.is_default
                );

                if (defaultFineness) {
                    state.form.options.fineness = defaultFineness.id;
                }
            }
        },
        setFormOption(state, { name, value }) {
            state.form.options[name] = value;
        },

        toggleFacetCollapse(state, facetSlug) {
            if (state.activeFacetCollapses.includes(facetSlug)) {
                state.activeFacetCollapses = state.activeFacetCollapses.filter(
                    (slug) => slug !== facetSlug
                );
            } else {
                state.activeFacetCollapses.push(facetSlug);
            }
        },

        closeFacetCollapse(state, facetSlug) {
            state.activeFacetCollapses = state.activeFacetCollapses.filter(
                (slug) => slug !== facetSlug
            );
        },
        disactivateAllFacetCollapses(state) {
            state.activeFacetCollapses = [];
        },

        toggleOptionCollapse(state, optionId) {
            state.activeOptionCollapse = optionId;
        },
        setErrors(state, errors) {
            state.errors = errors;
        },
        setBasicApvizConfiguration(state, configuration) {
            state.apvizBasicConfiguration = configuration;
        },
        setActualApvizConfiguration(state, configuration) {
            state.apvizActualConfiguration = configuration;
        },
        clearCurrentConfiguration(state) {
            state.current.configuration = {};
        },
        clearCurrentPrice(state) {
            state.current.price = {};
        },
        clearCurrentOptions(state) {
            state.current.options = {};
        },
        setIsLoadingCurrentConfiguration(state, isLoading) {
            state.isLoadingCurrentConfiguration = isLoading;
        },
        setRealisationTime: (state, realisationTime) => {
            state.current.realizationTime = realisationTime;
        },
        setError(state, value) {
            state.isError = value;
        },
    },
    actions: {
        async setCurrentConfiguration(context, productId) {
            context.commit('setIsLoadingCurrentConfiguration', true);

            const response = await getCurrentConfiguration(productId);

            if (response) {
                context.commit('setProductId', productId);
                context.commit('setCollection', response.collection.slug);
                context.commit('setCurrentConfiguration', response.list);
                context.commit('apviz/setConfiguration', response.view);
            }

            context.commit('setIsLoadingCurrentConfiguration', false);

            // console.log(response);
            return context.state.current.configuration;
        },

        async loadPrices(context, productId) {
            const response = await getPrices({
                productId,
                options: context.getters.getModifiedOptionsWithRequestStructure,
            });

            if (response) {
                context.commit('setPrices', response);
            }

            return context.state.current.price;
        },

        async loadOptions(context, productId) {
            const response = await getOptions(productId);

            context.commit('setOptions', response);
            context.commit('cleanFormOptionsThatDontExist', response);
            context.commit('setDefaultFormOptions', response);

            return context.state.current.options;
        },

        async addToCart(context) {
            context.commit('setIsAddingToCart', true);

            // walidacja
            const isValidated = await context.dispatch('validate');

            if (!isValidated) {
                context.commit('setIsAddingToCart', false);
                return;
            }

            const imageExist = await context.dispatch('isProductImage');

            // zapis obrazka
            if (!imageExist) {
                await context.dispatch('saveImage').catch((err) => {
                    console.error(err);
                });
            }

            // dodanie do koszyka
            const response = await addToCart(context.getters.getAddToCartProductConfiguration);

            // const data = await response.json();
            const html = await response.text();

            if (response.ok) {
                if (window.self !== window.top) {
                    // The page is in an iframe
                    handleAddToCartInIframe(html);
                } else {
                    // The page is not in an iframe
                    handleAddToCartOnSite();
                }
            }

            context.commit('setIsAddingToCart', false);
        },

        async validate(context) {
            const errors = {};

            if (!context.state.form.options.size) {
                // throw new Error('fineness is required');

                errors.size = i18n.global.t('errors.size');

                if (!context.getters.isActiveFacetCollapse('additional_options')) {
                    context.commit('toggleFacetCollapse', 'additional_options');
                }

                if (!context.getters.isActiveFacetCollapse('size')) {
                    context.commit('toggleOptionCollapse', 'size');
                }

                const summaryScrollableContainer = document.querySelector(
                    '#configurator-summary-scrollable-container'
                );

                window.bootstrap.Offcanvas.getInstance(
                    document.querySelector('#summary-offcanvas')
                ).hide();

                setTimeout(() => {
                    summaryScrollableContainer.scrollTop = summaryScrollableContainer.scrollHeight;
                }, 50);
            }

            context.commit('setErrors', errors);

            return Object.keys(errors).length === 0;
        },

        async isProductImage(context) {
            const response = await isProductImage(context.state.productId);
            return response.ok;
        },

        async saveImage(context) {
            const currentViewer = context.getters['apviz/getCurrentViewer'];

            // const prevViewpoint = await currentViewer.viewer.getCurrentViewpoint()

            const prevCamera = context.getters['apviz/getCameraState'];

            await context.dispatch(
                'apviz/changeCameraPosition',
                apvizDefaultShowcases['screenshot']
            );

            await currentViewer.viewer.update(
                context.getters['apviz/getApvizNewConfigurationWithDefaultValues']
            );

            const screenshot = await currentViewer.viewer.captureImage({
                imageHeight: 1500,
                imageWidth: 1500,
            });

            const base64 = await screenshot.toBase64();

            await context.dispatch('apviz/changeCameraPosition', prevCamera);

            await currentViewer.viewer.update(context.getters['apviz/getApvizNewConfiguration']);

            const response = await saveProductImage(context.state.productId, base64);

            return response;
        },

        loadShareableUrl(context) {
            const url = new URL(window.location.href);
            const params = qs.parse(url.search, { ignoreQueryPrefix: true });

            if (params.apvizView) {
                context.commit('apviz/setView', params.apvizView);
            }

            if (params.option) {
                Object.keys(params.option).forEach((optionName) => {
                    context.commit('setFormOption', {
                        name: optionName,
                        value: params.option[optionName],
                    });
                });
            }

            if (params.cameraState) {
                const cameraState = qs.parse(params.cameraState, {});

                context.commit('apviz/setCameraState', cameraState);
            }

            // camera position logic is handled inside apviz module (loading camera position from url)
        },

        async loadRealisationTime(context) {
            const response = await getRealisationTime({
                productId: context.state.productId,
                options: context.getters.getModifiedOptionsWithRequestStructure,
            });

            context.commit('setRealisationTime', response.availability_status);
        },
        handleReportError({ commit }, errorDescription) {
            console.log(commit);
            Sentry.captureMessage('Raport błędu od użytkownika', {
                level: 'error',
                extra: { userMessage: errorDescription },
            });
        },
    },
    modules: {
        spinners,
        apviz,
        modal,
    },
});

export default store;
