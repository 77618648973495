<script setup>
import { watch, computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useGtag } from 'vue-gtag-next';

import GroupCollapse from '@components/configurator/GroupCollapse.vue';
import GroupCollapseContent from '@components/configurator/GroupCollapseContent.vue';
import blueprint from '@config/facets-blueprint.js';
import EngraveCollapse from '@components/configurator/EngraveCollapse.vue';
import CollectionCollapse from '@components/configurator/CollectionCollapse.vue';
import AdditionalOptionsCollapse from '@components/configurator/AdditionalOptionsCollapse.vue';

const router = useRouter();
const route = useRoute();
const store = useStore();
const { query } = useGtag();

const timestamp = ref(Date.now());

const currentConfiguration = computed(() =>
    Object.keys(store.getters.getCurrentConfiguration)
        .filter((key) => store.getters.getCurrentConfiguration[key].quantity_for_root_facet)
        .reduce((memo, key) => ({ ...memo, [key]: store.getters.getCurrentConfiguration[key] }), {})
);

store.commit('spinners/toggleSpinner', 'configurator-summary-spinner');
await store.dispatch('setCurrentConfiguration', route.params.productId);
await store.dispatch('loadPrices', route.params.productId);
await store.dispatch('loadOptions', route.params.productId);
await store.dispatch('loadRealisationTime');
store.dispatch('loadShareableUrl');
store.commit('spinners/toggleSpinner', 'configurator-summary-spinner');

onMounted(() => {
    window.dispatchEvent(new CustomEvent('ConfiguratorSummaryLoaded'));
});

const track = () => {
    const getVariant = () => {
        const currentConfiguration = store.getters.getCurrentConfiguration;

        return `${currentConfiguration.metal?.facet?.translation?.name || ''}, ${
            currentConfiguration.stone?.facet?.translation?.name || null
        }`;
    };

    query({
        event: 'view_item',
        ecommerce: {
            currency: window.config?.currency?.code || null,
            value: store.getters.getCurrentPrice || null,
            items: [
                {
                    item_id: route.params.productId || null,
                    item_name: 'Configurator Product',
                    affiliation: window.location.hostname || null,
                    index: 0,
                    item_brand: 'SAVICKI',
                    item_category: 'ring',
                    item_category2: store.state.collection || null,
                    item_variant: getVariant(),
                    price: store.getters.getCurrentPrice || null,
                    quantity: 1,
                },
            ],
        },
    });
};

track();

watch(
    () => route.params.productId,
    async (newProductId) => {
        // store.commit('clearCurrentConfiguration');
        store.commit('spinners/toggleSpinner', 'configurator-summary-spinner');
        await store.dispatch('setCurrentConfiguration', newProductId);

        timestamp.value = Date.now();

        await store.dispatch('loadPrices', newProductId);
        await store.dispatch('loadOptions', newProductId);
        await store.dispatch('loadRealisationTime');
        store.commit('spinners/toggleSpinner', 'configurator-summary-spinner');
        // store.commit('disactivateAllFacetCollapses')

        track();
    }
);

watch(
    [
        () => store.state.form.options['fineness'],
        () => store.state.form.options['platinization'],
        () => store.state.form.options['hypoallergenic'],
    ],
    async () => {
        await store.dispatch('loadPrices', route.params.productId);
    },
    { deep: true }
);

watch(
    () => store.getters.getShareableLink,
    (val) => {
        router.push({ query: { ...val } });
    },
    {
        deep: true,
    }
);

const handleSetViewpoint = (viewpoint) => {
    store.getters['apviz/getCurrentViewer'].viewer.setViewpoint({
        viewpoint: viewpoint,
    });
};
const handleCollapseClicked = (clickedHeaderElement, facetSlug) => {
    clickedHeaderElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
    });
};
</script>

<template>
    <div class="d-flex flex-column justify-content-center">
        <div class="collapse-wrapper" data-collapse-key="collectionCollapse">
            <CollectionCollapse
                @set-viewpoint="handleSetViewpoint"
                @collapse-clicked="
                    (headerElement) => handleCollapseClicked(headerElement, 'collection')
                "
            />
        </div>
        <div
            v-for="(productGroup, key) in currentConfiguration"
            :key="`${timestamp}_${key}_wrapper`"
            class="collapse-wrapper"
            :data-collapse-key="`${key}Collapse`"
        >
            <GroupCollapse
                :root-facet-slug="key"
                :title="productGroup.root_facet.translation.name || ''"
                :product-group="productGroup"
                @collapse-clicked="(headerElement) => handleCollapseClicked(headerElement, key)"
                @set-viewpoint="handleSetViewpoint"
            >
                <template #collapseContent>
                    <GroupCollapseContent :product-group="productGroup">
                        <template #default="slotProps">
                            <!-- <Transition name="fade"> -->
                            <component
                                :is="blueprint[key]?.component || blueprint['default'].component"
                                :product-group="productGroup"
                                :variants="slotProps.variants"
                            />
                            <!-- </Transition> -->
                        </template>
                    </GroupCollapseContent>
                </template>
            </GroupCollapse>
        </div>
        <div class="collapse-wrapper" data-collapse-key="engraveCollapse">
            <EngraveCollapse
                v-if="store.getters.showEngraveModule"
                :key="`${route.params.productId}_engrave`"
                @set-viewpoint="handleSetViewpoint"
                @collapse-clicked="
                    (headerElement) => handleCollapseClicked(headerElement, 'engrave')
                "
            />
        </div>
        <div class="collapse-wrapper" data-collapse-key="additionalOptionsCollapse">
            <AdditionalOptionsCollapse
                v-if="store.getters.showAdditionalOptionsModule"
                :key="`${route.params.productId}_additional_options`"
                @set-viewpoint="handleSetViewpoint"
                @collapse-clicked="handleCollapseClicked"
            />
        </div>
    </div>
</template>
