<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import GroupCollapseImage from './GroupCollapseImage.vue';
import ContentSpinner from '@components/ContentSpinner.vue';

const props = defineProps({
    rootFacetSlug: {
        type: String,
        required: true,
    },
    title: {
        type: String,
        required: true,
    },
    productGroup: {
        type: Object,
        required: true,
    },
});

const emits = defineEmits(['collapseClicked', 'setViewpoint']);

const store = useStore();

const headerElement = ref(null);

const isActive = computed(() => store.getters.isActiveFacetCollapse(props.rootFacetSlug));

const handleCollapse = () => {
    store.commit('toggleFacetCollapse', props.rootFacetSlug);
    emits('collapseClicked', headerElement.value);
};

// console.log(props)

// obsluga wyslania requestu po dane jesli to konieczne (jedynie przy pierwszym otwarciu collapse)
</script>

<template>
    <div>
        <div ref="headerElement" class="facet-collapse-header grid" @click="handleCollapse">
            <div class="g-col-2">
                <GroupCollapseImage
                    :key="`${props.productGroup.facet.slug}`"
                    :product-group="props.productGroup"
                    :root-facet="props.productGroup.root_facet"
                />
            </div>
            <div class="g-col-8 text-center text-lg-start">
                <strong class="fs-6">{{ props.title }}</strong>
            </div>
            <div class="g-col-2 text-end">
                <span v-if="!isActive" class=""
                    ><font-awesome-icon icon="fa-light fa-circle-plus" size="xl"
                /></span>
                <span v-else class=""
                    ><font-awesome-icon icon="fa-solid fa-circle-minus" size="xl"
                /></span>
            </div>
        </div>
        <Transition name="max-height">
            <div
                v-if="isActive"
                class="bg-light d-flex align-items-center justify-content-center"
                style="height: 150px"
            >
                <div class="w-100 position-relative">
                    <Transition name="fade">
                        <Suspense>
                            <slot name="collapseContent"></slot>

                            <template #fallback>
                                <div>
                                    <ContentSpinner />
                                </div>
                            </template>
                        </Suspense>
                    </Transition>
                </div>
            </div>
        </Transition>
    </div>
</template>
