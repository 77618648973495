<script setup>
import { defineAsyncComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const store = useStore();
const { t } = useI18n();

const toggleModal = () => {
    store.dispatch('modal/activateModal', {
        title: t('feedback.title'),
        contentComponent: defineAsyncComponent(() => import('@components/modals/Sentry.vue')),
    });
};

const isError = computed(() => store.state.isError);
</script>

<template>
    <button
        v-if="isError"
        class="floating-button position-absolute bottom-0 start-0 m-3 bg-warning bg-gradient border-warning bg-opacity-50"
        @click="toggleModal"
    >
        <font-awesome-icon icon="fal fa-triangle-exclamation" size="lg" />
    </button>
</template>

<style>
.floating-button {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 42px;
    border: none;
    cursor: pointer;
    color: #000000;
}
</style>
