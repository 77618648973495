<script setup>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';

import RingBoxIcon from '@assets/custom_icons/ringbox3.svg';
import { apvizDefaultShowcases } from '@config/apviz.js';

const store = useStore();

const showBoxSwitched = ref(false);

const handleViewPick = (viewSlug) => {
    const pickedView = store.getters['apviz/getApvizPickedView'];

    if (pickedView === viewSlug) {
        store.commit('apviz/setView', 'onlyring');
    } else {
        store.commit('apviz/setView', viewSlug);
    }
};

watch(
    () => store.getters['apviz/getApvizPickedView'],
    (pickedView) => {
        const d = apvizDefaultShowcases[pickedView];
        store.dispatch('apviz/changeCameraPosition', d);

        if (['whitebox', 'redbox', 'blackbox'].includes(pickedView)) {
            showBoxSwitched.value = true;
        } else {
            showBoxSwitched.value = false;
        }
    }
);
</script>

<template>
    <div class="position-absolute top-0 end-0 px-3 py-2 d-flex gap-3 z-3">
        <div
            :class="{
                'engraving-symbol': true,
                'is-active': store.getters['apviz/getApvizPickedView'] === 'onlyring',
            }"
            style="width: 34px; height: 34px"
            @click="() => handleViewPick('onlyring')"
        >
            <font-awesome-icon icon="fal fa-ring-diamond" />
        </div>

        <Transition name="max-width">
            <div v-if="showBoxSwitched" class="d-flex align-items-center gap-3">
                <div
                    :class="{
                        'box-symbol': true,
                        border: true,
                        'border-white': true,
                        'is-active': store.getters['apviz/getApvizPickedView'] === 'blackbox',
                    }"
                    style="color: black"
                    @click="() => handleViewPick('blackbox')"
                >
                    <font-awesome-icon icon="fas fa-circle" />
                </div>
                <div
                    :class="{
                        'box-symbol': true,
                        'is-active': store.getters['apviz/getApvizPickedView'] === 'redbox',
                    }"
                    style="color: red"
                    @click="() => handleViewPick('redbox')"
                >
                    <font-awesome-icon icon="fas fa-circle" />
                </div>
                <div
                    :class="{
                        'box-symbol': true,
                        'is-active': store.getters['apviz/getApvizPickedView'] === 'whitebox',
                    }"
                    style="color: white"
                    @click="() => handleViewPick('whitebox')"
                >
                    <font-awesome-icon icon="fas fa-circle" />
                </div>
            </div>
        </Transition>

        <div
            :class="{
                'engraving-symbol': true,
                'is-active': ['whitebox', 'blackbox', 'redbox'].includes(
                    store.getters['apviz/getApvizPickedView']
                ),
            }"
            style="width: 34px; height: 34px"
            @click="() => handleViewPick('whitebox')"
        >
            <!-- <font-awesome-icon icon="fal fa-box-heart" /> -->
            <img
                :src="RingBoxIcon"
                alt="Box image"
                title="Box image"
                width="16"
                style="pointer-events: none"
            />
        </div>

        <div
            :class="{
                'engraving-symbol': true,
                'is-active': store.getters['apviz/getApvizPickedView'] === 'hand',
            }"
            style="width: 34px; height: 34px"
            @click="() => handleViewPick('hand')"
        >
            <font-awesome-icon icon="fal fa-hand" />
        </div>
    </div>
</template>

<style lang="css">
.box-symbol {
    /* color: #fff; */
    border: 2px solid #d0d0d0;
    border-radius: 100%;

    width: 18px;
    height: 18px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.box-symbol.is-active {
    border-color: #000;
}
</style>
