<script setup>
import { ref, computed } from 'vue';
import Spinner from '@components/Spinner.vue';
// import Engrave from "@components/options/Engrave.vue"

import { useStore } from 'vuex';

import Options from '@components/options/Options.vue';

const store = useStore();
const emits = defineEmits(['setViewpoint', 'collapseClicked']);

const headerElement = ref(null);

const isActive = computed(() => store.getters.isActiveFacetCollapse('additional_options'));

const handleCollapse = () => {
    store.commit('toggleFacetCollapse', 'additional_options');
    emits('collapseClicked', headerElement.value);
};

// const engraveHasValue = computed(() => store.state.form.options[engraveOptionName]?.length > 0 ? 'Tak' : 'Nie');
// const engraveValue = computed(() => store.getters.getOptionValue(engraveOptionName) ? `(${store.getters.getOptionValue(engraveOptionName)})` : '');
</script>

<template>
    <div>
        <div ref="headerElement" class="facet-collapse-header grid" @click="handleCollapse">
            <div class="g-col-2"></div>
            <div class="g-col-8 text-center text-lg-start">
                <strong class="fs-6">{{
                    $t('components.configurator.additional-options-collapse.text1')
                }}</strong>
            </div>
            <div class="g-col-2 text-end">
                <span v-if="!isActive" class=""
                    ><font-awesome-icon icon="fa-light fa-circle-plus" size="xl"
                /></span>
                <span v-else class=""
                    ><font-awesome-icon icon="fa-solid fa-circle-minus" size="xl"
                /></span>
            </div>
        </div>
        <Transition name="max-height">
            <div v-if="isActive">
                <div class="py-3 bg-light">
                    <Suspense>
                        <slot name="collapseContent">
                            <Options />
                        </slot>

                        <template #fallback>
                            <Spinner />
                        </template>
                    </Suspense>
                </div>
            </div>
        </Transition>
    </div>
</template>
