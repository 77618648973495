<script setup>
    import SummaryOffcanvasContent from "@components/configurator/SummaryOffcanvasContent.vue";

    const props = defineProps({
        id: {
            type: String,
            required: true
        }
    });
</script>

<template>
    <div 
        :id="props.id || 'offcanvasExample'" 
        class="offcanvas offcanvas-end bg-primary" 
        tabindex="-1" 
        aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
            <div class="d-flex w-100 justify-content-between text-white cursor-pointer" data-bs-dismiss="offcanvas">
                <p class="mb-0 fw-bold">
                    {{ $t('components.summary-offcanvas-desktop.text1') }}
                </p>

                <span>
                    <font-awesome-icon icon="fa-light fa-angle-right" size="lg"/>
                </span>
            </div>
        </div>
        <div class="offcanvas-body">
            <SummaryOffcanvasContent />
        </div>
    </div>
</template>
