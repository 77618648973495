// export const summaryBlueprint = {
//     engrave: {
//         options: [
//             {
//                 label: 'Grawer',
//                 valueKey: 'engrave'
//             },
//             {
//                 label: 'Krój pisma',
//                 valueKey: 'engrave_font'
//             }
//         ]
//     }
// }

export const optionsBlueprint = {
    fineness: {
        
    },
    size: {

    },
    platinization: {

    },
    hypoallergenic: {
        
    }
}

export const summaryRemainingOptions = Object.keys(optionsBlueprint)